import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import Advantages from '../components/reusable/Advantages';
import Geography from '../components/home/HomeGeography';
import Phones from '../components/reusable/Phones';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ContactGeoWrap from '../components/reusable/ContactGeoWrap';

const AboutStyleWrap = styled.div`
  margin-bottom: 3rem;
  h3 {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;

    img {
      display: inline-block;
      vertical-align: middle;
      
    }
  }

  .achievements {
    text-align: center;

    img {
      display: inline-block;
      margin-right: 0.5rem;
      max-height: 100px;
    }
  }
`;

const AboutUs = () => {
  const icons = useStaticQuery(graphql`
    query {
          googlePremierPartner: allFile(filter: {name: {eq: "google-premier-partner"}, extension: {eq: "jpg"}}) {
             edges {
                 node {
                     publicURL
                 }
             }
         }
         googleAnalyticsTechnologyPartner: allFile(filter: {name: {eq: "google-analytics-technology-partner"}, extension: {eq: "png"}}) {
             edges {
                 node {
                     publicURL
                 }
             }
         }
         logoFacebook: allFile(filter: {name: {eq: "1logo-facebook"}, extension: {eq: "jpg"}}) {
            edges {
                node {
                    publicURL
                }
            }
          }
          bingPartner: allFile(filter: {name: {eq: "BING-partner1"}, extension: {eq: "png"}}) {
            edges {
                node {
                    publicURL
                }
            }
          }
          logoOptimozg: allFile(filter: {name: {eq: "logo"}, extension: {eq: "jpg"}}) {
            edges {
                node {
                    publicURL
                }
            }
          } 
     }
 `);
 return (
  <Layout>
    <SEO title="About Optimozg" />
    <PageHeader title="About Optimozg" />
    <div className="container">
      <AboutStyleWrap>
        <h3>A few facts about&nbsp;&nbsp;
          <img src={icons.logoOptimozg.edges[0].node.publicURL} alt="OPTIMOZG" width="201" height="47" />
        </h3>
        <ul>
          <li>Marketing agency</li>
          <li>Founded in 2011</li>
          <li>Always ROI-oriented (return-on-investment) and Net Profit is our major goal</li>
          <li>Total marketing budget is $500k/mo</li>
          <li>Narrowly and Deeply focused experts</li>
          <li>Recognized expertise and over 40 certificates</li>
        </ul>
        <h3>More facts:</h3>
        <p>Optimozg is an online marketing agency deeply focused on PPC Online Marketing (more traffic) and Website Conversion Rate Optimization (more leads/sales). We don’t do SEO, Website Design, Blog posting etc. we very deeply focus on Online Marketing, that’s why we are great at the craft.</p>
        <div className="achievements">
          <h3>Achievements:</h3>
          <p><em><strong>Prizewinner of Google Adwords Award the best EU display campaign 2016</strong></em></p>
          <p>
            <img src={icons.googlePremierPartner.edges[0].node.publicURL} alt="Google Premier Partner" />
            <img src={icons.googleAnalyticsTechnologyPartner.edges[0].node.publicURL} alt="Google Analytics Technology Partner" />
            <img src={icons.logoFacebook.edges[0].node.publicURL} alt="Facebook" />
            <img src={icons.bingPartner.edges[0].node.publicURL} alt="Bing Partner" />
          </p>
        </div>
        
        <h3>Approach:</h3>
        <ul>
          <li>Profit and ROI first. We believe that all results can be measured in $$$</li>
          <li>Quality and only Quality. No cutting edges. Good communication</li>
          <li>Narrow focusing as key to success. Be the best in a narrow niche is the motto of our experts</li>
          <li>Discover and be the first. Use tested but always try new. Be on the crest of the wave, be faster than the others</li>
          <li>Competitors is a huge source of growth. Analyze them!</li>
          <li>Always test. Market knows what’s better, never rely on feeling</li>
          <li>Track everything. You can’t manage it until you measure it</li>
          <li>Make a plan and meet Deadline. Plan before you go, no mess/chaos. And be on time with the results</li>
          <li>Respect client’s time. Bi-weekly reporting, Don’t annoy the client with obvious things</li>
        </ul>
        <h3>Core competencies (Just 2):</h3>
        <p><strong>1. PPC Online marketing:</strong></p>
        <ul>
          <li>PPC Research and Audits</li>
          <li>Creating PPC marketing strategy plans</li>
          <li>ROI-oriented PPC optimization</li>
          <li>PPC tools, reportings, automated API solutions, Adwords scripts</li>
          <li>We work with all popular PPC platforms: Adwords, Facebook ads, Bing ads, Doubleclick, Ceriteo, ADroll etc<br/>
          and with all ad types: Search ads, Video ads, Display ads, Remarketing (Dynamic Remarketing), Shopping/Product ads, Native ads, Mobile ads, Canvas ads, Leads ads, Mobile App ads etc.</li>
        </ul>
        <p><strong>2. To be successful in PPC we need to generate sales, the second core competence is Website Conversion Rate Optimization:</strong></p>
        <ul>
          <li>Conv. rate optimization (Online marketing Research and Audits, customer journey analyzing, A/B Testing, usability optimization)</li>
          <li>Advanced tracking (ecommerce tracking, sales funnel tracking, offline sales/call tracking, google tag manager set up)</li>
          <li>Analytics-related automation tools, scripts, integrations with all types of 3rd services and CRM’s</li>
          <li>Advanced reporting (ROI/net profit/LTV reporting, reports visualization, API integrations and solutions)</li>
        </ul>
        
        <h3>Other services:</h3>
        <ul>
          <li>Consulting</li>
          <li>Strategy designing</li>
          <li>External audits</li>
          <li>Competitors analysis</li>
        </ul>
        <h3>Our Clients:</h3>
        <ul>
          <li>Well-known B2B and B2C businesses</li>
          <li>70% ecommerce (retail, clothes, jewelry, flowers/gifts, health &amp; beauty)</li>
          <li>30% services (software, SAAS, real estate, finance, healthy/beauty, trainings)</li>
          <li>Non profit organizations</li>
        </ul>
      </AboutStyleWrap>
    </div>
    <Advantages />
    <ContactGeoWrap>
      <Geography />
      <Phones />
    </ContactGeoWrap>
  </Layout>
  )
}

export default AboutUs
